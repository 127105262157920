<template>
    <div class="common_content innovation_box">
         <div class="social_title animated fadeInDown">
            科研创新 <br/><span>/  RESEARCH INNOVATION</span>
        </div>
        <div class="s_title_small animated bounceInRight"><span>滚动鼠标继续   <span style="color:#EE660E">/</span>   Scroll to Next</span><img src="../../assets/groupIntro/mouse_icon.gif"/></div>
        <div class="line_b"></div>
        <div class="innova_content">
            <div class="innova_top">
                <div class="top_img animated bounceInRight">
                    <img src="../../assets/innovation/innova_bg_01.png"/>
                </div>
                <div class="top_text" id="text_1">
                    以设计为先导，琪尔特19年设计师文化沉淀，<br/>
                    希望展示给世界的是“中国设计”
                </div>
            </div>
            <div class="nova_img_text animated bounceInRight delay-c2s">
                <img src="../../assets/innovation/innova_text.png"/>
            </div>
            <div class="nova_title_box">
                <div class="nova_title" id="ip_animate">科研团队</div>
                <div class="nova_title_sub nova_title_sub2" id="ip_animate2">
                    从集团创立之初，琪尔特就投入建立设计研发部门并进行公司化运作，同时开办培训班，建立研发室，先后为中国鞋服行业培养了一大批顶尖的研发人员，间接推动鞋服产业发展。
                    <p>2015年，为了配合集团转型发展，琪尔特更是以“走出去-引进来”的方式，通过让整个品牌团队与外界积极碰撞，全面提升设计科研团队的国际视野。</p>
                    <p>琪尔特持续引进国际国内高级设计师人才，目前琪尔特已有员工800多人，其中研发设计人员占50%以上。</p>
                </div>
                 <div class="title_img">
                    <img id="ip_img_animate" src="../../assets/innovation/innova_bg_02.png"/>
                </div>
            </div>
            <div class="nova_title_box">
                <div class="nova_title" id="kjcp">科技产品</div>
                <div class="nova_title_sub" id="kjcp_t">
                    基于历年在鞋服设计、研发中的探索与实践，琪尔特集团不仅建立了包含六大核心、全品类科技的“科技智库”体系，
                    并且设立科技智库研究中心进行健康数据分析研究，最终确立以“创新智造”为核心，整合优势资源以求促进整个产业的转型升级，
                    延伸鞋服产业的生产边界，立志打造多维度的科技支撑品牌的战略定位。
                    <p>未来，更会与亚洲乃至国际相关科技机构联合建设“科技智库研究中心”，完备科研体系</p>
                </div>
                 <div class="title_img">
                    <img id="kjcp_img" src="../../assets/innovation/innova_bg_03.png"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted(){
        window.addEventListener('scroll', this.getScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.getScroll);
    },
    methods:{
        getScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (60 < scrollTop && scrollTop < 200) {
                let dom1 = document.getElementById('text_1');
                dom1.classList.add('animated');
                dom1.classList.add('fadeInDown');
            }else if(350<scrollTop && scrollTop < 500){
                let dom1 = document.getElementById('ip_animate');
                dom1.classList.add('animated');
                dom1.classList.add('fadeInDown');
                
            } else if(400<scrollTop && scrollTop < 700){
                let dom3 = document.getElementById('ip_animate2');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');

               
            } else if(700<scrollTop && scrollTop < 1400){
                let dom3 = document.getElementById('ip_img_animate');
                dom3.classList.add('animated');
                dom3.classList.add('bounceInRight');

               
            } else if(1400<scrollTop && scrollTop < 1500){
                let dom3 = document.getElementById('kjcp');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');

            } else if(1510<scrollTop && scrollTop < 1687){
                let dom3 = document.getElementById('kjcp_t');
                dom3.classList.add('animated');
                dom3.classList.add('fadeInDown');

            }else if(scrollTop > 1900){
                let dom3 = document.getElementById('kjcp_img');
                dom3.classList.add('animated');
                dom3.classList.add('bounceInRight');
            }
        },
    }
}
</script>
<style scoped>
.common_content{
    margin-left: 370px;
    margin-top: 4vh;
}
.innovation_box{
    margin-right: 240px;
}
.social_title{
font-size: 46px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #000000;
}
.social_title span{
    font-size: 25px;
    color: #EE660E;
}
.line_b{
    height: 2px;
background: #F2F2F2;
margin: 36px 0 26px 0;
}
.s_title_small{
  font-size: 17px;
font-family: AlibabaPuHuiTiL;
font-weight: 400;
color: #000000;
    padding-top: 30px;
        text-align: right;
}
.s_title_small img{
      vertical-align: middle;
    margin-left: 5px;
}
.innova_content{
    position: relative;
}
.innova_top{

}
.nova_img_text{
    position: absolute;
    top: 342px;
    right: 24px;
}
.nova_img_text img{
    width: 100%;
}
.top_img{
    max-width: 726px;
}
.top_img img{
width: 100%;
vertical-align: bottom;
}
.top_text{
font-size: 28px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #FFFFFF;
line-height: 58px;
background: #EE660E;
padding: 34px;
}
.nova_title_box{
    margin-top: 96px;
}
.nova_title{
font-size: 33px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #221815;
text-align: center;

}
.nova_title_sub{
font-size: 16px;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #000000;
line-height: 2;
padding: 62px 0 82px 0;
}
.nova_title_sub2{
    padding-right: 240px;
}
.title_img{

}
.title_img img{
width: 100%;
}
@media screen and (max-width:1500px){
    .top_img{
        max-width: 420px;
    }
    .top_text{
            font-size: 18px;
    line-height: 30px;
    }
    .nova_title_sub2{
        padding-right: 82px;
    }
    .nova_img_text{
        position: absolute;
        top: 209px;
        right: 0;
    }
    .nova_img_text img{
        width: 82%;
    }
}
.delay-c2s{
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}
</style>